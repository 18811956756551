/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.form-field {
    &_size_s {
        padding-bottom: var(--gap-m);
    }

    &_size_m {
        padding-bottom: var(--gap-xl);
    }

    &_size_l {
        padding-bottom: var(--gap-2xl);
    }

    &_size_xl {
        padding-bottom: var(--gap-3xl);
    }
}
