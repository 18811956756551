/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.amount {
    display: inline-block;
    white-space: nowrap;

    &__currency {
        display: inline-block;
        text-align: right;
    }

    &__major {
        .amount_bold & {
            font-weight: var(--font-weight-bold);
        }
    }

    &__separator,
    &__minor,
    &__currency {
        opacity: var(--opacity-minor);

        .amount_bold & {
            font-weight: var(--font-weight-bold);
        }
    }

    .heading {
        margin: 0;
    }
}
