/* This Source Code Form is subject to the terms of the Mozilla Public
* License, v. 2.0. If a copy of the MPL was not distributed with this
* file, You can obtain one at http://mozilla.org/MPL/2.0/. */

.icon_size_l.icon_name_arrow-left.icon_theme_alfa-on-white {
    background-image: url('./icon_arrow-left_l_black.svg');
}

.icon_size_l.icon_name_arrow-left.icon_theme_alfa-on-color {
    background-image: url('./icon_arrow-left_l_white.svg');
}

.icon_size_m.icon_name_arrow-left.icon_theme_alfa-on-white {
    background-image: url('./icon_arrow-left_m_black.svg');
}

.icon_size_m.icon_name_arrow-left.icon_theme_alfa-on-color {
    background-image: url('./icon_arrow-left_m_white.svg');
}

.icon_size_s.icon_name_arrow-left.icon_theme_alfa-on-white {
    background-image: url('./icon_arrow-left_s_black.svg');
}

.icon_size_s.icon_name_arrow-left.icon_theme_alfa-on-color {
    background-image: url('./icon_arrow-left_s_white.svg');
}

.icon_size_xl.icon_name_arrow-left.icon_theme_alfa-on-white {
    background-image: url('./icon_arrow-left_xl_black.svg');
}

.icon_size_xl.icon_name_arrow-left.icon_theme_alfa-on-color {
    background-image: url('./icon_arrow-left_xl_white.svg');
}

.icon_size_xxl.icon_name_arrow-left.icon_theme_alfa-on-white {
    background-image: url('./icon_arrow-left_xxl_black.svg');
}

.icon_size_xxl.icon_name_arrow-left.icon_theme_alfa-on-color {
    background-image: url('./icon_arrow-left_xxl_white.svg');
}
