/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@import "../vars.css";

.paragraph {
    margin: 0 0 var(--gap-m);
    padding: 0;
    font-family: var(--font);
    font-weight: var(--font-weight-normal);

    font-size: var(--font-size-m);
    line-height: var(--line-height-normal);

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    &_view_lead {
        font-size: var(--font-size-xl);
    }

    &_view_small {
        font-size: var(--font-size-s);
    }

    &_theme_alfa-on-color {
        color: var(--color-content-accent-alfa-on-color);
    }

    &_theme_alfa-on-white {
        color: var(--color-content-accent-alfa-on-white);
    }
}
