/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

.form {
    padding-bottom: 60px;

    &:last-child {
        padding-bottom: 0;
    }

    &__footer {
        padding-top: var(--gap-l);
    }
}

.form_view_line {
    &.form_size_s,
    &.form_size_m {
        .form__footer {
            padding-left: 115px;
        }
    }

    &.form_size_l,
    &.form_size_xl {
        .form__footer {
            padding-left: 165px;
        }
    }
}
